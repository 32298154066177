<template>
    <styled-interface>
        <template #interface-toolbar-external>
            <agency-toolbar-global />
        </template>

        <template #interface-heading>
            Advertising
        </template>

        <styled-card>
            <template #heading>
                Meta Advertising Offline Events
            </template>
            <template #action-buttons>
                <div class="grey--text">
                    <em>
                        Updated <time-ago :value="now.toDate()" />
                    </em>
                </div>
                <action-button
                    icon="refresh"
                    :loading="showLoader"
                    @click="loadData">
                    refresh
                </action-button>
                <action-button
                    :disabled="loading"
                    icon="download"
                    @click="exportCSV">
                    Export CSV
                </action-button>
            </template>
            <div class="groups-picker">
                <div class="groups-picker__holder">
                    <groups-picker-global :disabled="showLoader" />
                </div>
            </div>
            <progressbar-timed
                v-model="loading"
                class="pa-5"
                :gain="loadingGain"
                :top-end="98"
                @complete="showLoader = false">
                {{ progressMessage }}
            </progressbar-timed>
            <div
                v-if="!showLoader && !groupsLoading"
                class="">
                <styled-table
                    class="advertising-table offline-events-table"
                    :headers="headers"
                    :items="dealerInsights"
                    :rows-per-page="50"
                    min-width="1250px"
                    has-search>
                    <template #filters>
                        <div class="table-field">
                            <v-select
                                v-model="eventType"
                                :items="FACEBOOK_OFFLINE_EVENT_TYPES"
                                item-text="name"
                                item-value="key"
                                label="Event Type"
                                class="styled-field mr-2"
                                return-object />
                        </div>
                    </template>

                    <template #items="{ item, index }">
                        <tr
                            :class="[
                                (index % 2) ? 'row-even' : 'row-odd'
                            ]">
                            <td class="dark-column">
                                <router-link
                                    :to="{ name: 'dealer', params: { dealer_id: item.dealer_id }}">
                                    {{ item.dealer.name }}
                                </router-link>
                            </td>

                            <td class="py-2">
                                <div class="layout align-center">
                                    <a
                                        href="#"
                                        target="_blank"
                                        class="mr-1"
                                        @click.prevent="openAdAccount(item.id)">
                                        {{ item.name }}
                                    </a>
                                    <styled-tooltip
                                        position="top"
                                        class="ml-auto action-icon">
                                        <template #content>
                                            View settings in Business Manager
                                        </template>
                                        <a
                                            href="#"
                                            @click.prevent="openAdAccountSettings(item.id)">
                                            <icon
                                                name="cog"
                                                size="15" />
                                        </a>
                                    </styled-tooltip>
                                    <styled-tooltip
                                        position="top"
                                        class="ml-2 action-icon">
                                        <template #content>
                                            View all dealer Meta stats
                                        </template>
                                        <a
                                            href="#"
                                            @click.prevent="goToDealerStats(item.dealer_id)">
                                            <icon
                                                name="graph"
                                                size="15" />
                                        </a>
                                    </styled-tooltip>
                                </div>
                            </td>

                            <table-cell
                                :is-null="!item.insights.spend.value"
                                class="text-xs-center">
                                {{ item.insights.spend.formatted }}
                            </table-cell>

                            <table-cell
                                :is-null="!item.insights.reach.value"
                                class="text-xs-center">
                                {{ item.insights.reach.formatted }}
                            </table-cell>

                            <table-cell
                                v-for="window in FACEBOOK_ATTRIBUTION_WINDOWS"
                                :key="window.key"
                                :is-null="!item.insights[`offline_${eventType.key}_${window.key}`].value"
                                class="text-xs-center">
                                {{ item.insights[`offline_${eventType.key}_${window.key}`].formatted }}
                            </table-cell>

                            <table-cell
                                :is-null="!item.insights[`offline_${eventType.key}`].value"
                                class="text-xs-center">
                                {{ item.insights[`offline_${eventType.key}`].formatted }}
                            </table-cell>

                            <table-cell
                                :is-null="!item.insights[`cost_per_offline_${eventType.key}`].value"
                                class="text-xs-center">
                                {{ item.insights[`cost_per_offline_${eventType.key}`].formatted }}
                            </table-cell>
                        </tr>
                    </template>
                </styled-table>
            </div>
        </styled-card>
    </styled-interface>
</template>

<script>
import { get } from 'lodash';
import moment from 'moment';
import { mapState, mapGetters } from 'vuex';
import AgencyToolbarGlobal from '@/components/globals/AgencyToolbarGlobal';
import TableCell from '@/components/globals/TableCell';
import ActionButton from '@/components/globals/ActionButton';
import StyledTable from '@/components/globals/StyledTable';
import StyledTooltip from '@/components/globals/StyledTooltip';
import StyledInterface from '@/components/globals/StyledInterface';
import StyledCard from '@/components/globals/StyledCard';
import ProgressbarTimed from '@/components/globals/ProgressbarTimed';
import TimeAgo from '@/components/globals/TimeAgo';
import Icon from '@/components/globals/Icon';
import getNumberFormats from '@/helpers/numberFormats';
import { FACEBOOK_OFFLINE_EVENT_TYPES, FACEBOOK_ATTRIBUTION_WINDOWS } from '@/helpers/globals';
import { ExportToCsv } from 'export-to-csv';
import getAgencyInsights from '@/apis/facebook/getAgencyInsights';
import formatInsightsData from '@/apis/facebook/formatInsightsData';
import GroupsPickerGlobal from '@/components/globals/GroupsPickerGlobal';

const attributionWindows = [
    '1d_view',
    '1d_click',
    '7d_view',
    '7d_click',
    '28d_view',
    '28d_click'
];

export default {
    name: 'ResellerFacebookOfflineEvents',
    components: {
        AgencyToolbarGlobal,
        GroupsPickerGlobal,
        ActionButton,
        ProgressbarTimed,
        StyledCard,
        StyledInterface,
        StyledTable,
        StyledTooltip,
        TableCell,
        TimeAgo,
        Icon
    },
    data() {
        return {
            FACEBOOK_OFFLINE_EVENT_TYPES,
            FACEBOOK_ATTRIBUTION_WINDOWS,
            eventType: null,
            loading: false,
            showLoader: false,
            loadingAdAccounts: false,
            insights: [],
            adAccounts: [],
            loadingInsights: false,
            progressMessage: '',
            loadingGain: 0.5,
            numberFormats: getNumberFormats(),
            defaultHeaders: [
                {
                    text: 'Dealer Name',
                    id: 'name',
                    value: 'dealer.name',
                    align: 'left',
                    width: '10%',
                    sortable: true,
                    cellAlign: 'left',
                },
                {
                    text: 'Ad Account',
                    id: 'ad_account',
                    value: 'name',
                    align: 'left',
                    width: '12%',
                    sortable: true,
                    cellAlign: 'left',
                },
                {
                    text: 'Spend',
                    id: 'spend',
                    value: 'insights.spend.value',
                    align: 'left',
                    width: '4%',
                    sortable: true,
                    total: true,
                    average: true,
                    format: 'currency',
                    tooltip: this.$t('metrics.spend'),
                    cellAlign: 'center'
                },
                {
                    text: 'Reach',
                    id: 'reach',
                    value: 'insights.reach.value',
                    align: 'left',
                    width: '4%',
                    sortable: true,
                    total: true,
                    average: true,
                    format: 'number',
                    tooltip: this.$t('metrics.reach'),
                    cellAlign: 'center'
                }
            ],
            now: null,
            startOfMonth: null,
            endOfMonth: null,
            yesterday: null,
            daysInMonth: null,
            daysPassedInMonth: null,
            daysRemainingInMonth: null,
            minutesInMonth: null,
            minutesPassedInMonth: null,
            minutesRemainingInMonth: null,
            secondsInMonth: null,
            secondsPassedInMonth: null,
            secondsRemainingInMonth: null,
            noGroups: false,
        };
    },
    computed: {
        ...mapState({
            agency: (state) => state.agency.currentAgency,
            dateRange: (state) => state.metrics.dateRange,
            selectedGroups: (state) => state.agency.selectedGroups,
            groupsLoading: (state) => state.agency.groupsLoading,
            spendOverrideDisable: (state) => state.metrics.spendOverrideDisable,
            attribution: (state) => state.metrics.facebook.ads.attribution
        }),
        ...mapGetters([
            'userIsAdmin'
        ]),
        selectedGroupsIds() {
            return this.selectedGroups.flatMap(group => {
                return group?.id ? group.id : [];
            });
        },
        headers() {
            const field = `offline_${this.eventType.key}`;

            const headers = [];

            FACEBOOK_ATTRIBUTION_WINDOWS.forEach(window => {

                const field = `offline_${this.eventType.key}_${window.key}`;

                headers.push({
                    text: window.name,
                    id: field,
                    value: `insights.${field}.value`,
                    align: 'left',
                    width: '4%',
                    sortable: true,
                    total: true,
                    average: true,
                    format: 'number',
                    cellAlign: 'center'
                });
            });

            headers.push({
                text: 'Total',
                id: field,
                value: `insights.${field}.value`,
                align: 'left',
                width: '4%',
                sortable: true,
                total: true,
                average: true,
                format: 'number',
                cellAlign: 'center'
            });

            const costPerField = `cost_per_offline_${this.eventType.key}`;

            headers.push({
                text: 'Cost Per',
                id: costPerField,
                value: `insights.${costPerField}.value`,
                align: 'left',
                width: '4%',
                sortable: true,
                total: true,
                average: true,
                format: 'currency',
                cellAlign: 'center'
            });

            return [
                ...this.defaultHeaders,
                ...headers
            ];
        },
        dealerInsights() {
            return this.insights.map(account => {
                const newAccount = { ...account };

                const dealer = this.dealers.find(dealer => dealer.id == account.dealer_id);

                const spendOverride = dealer.configuration?.facebook_ad_spend_override || null;

                newAccount.insights = formatInsightsData(account.insights, {
                    spendOverride: this.spendOverrideDisable ? null : spendOverride,
                    attribution: this.attribution,
                    attributionWindows
                });

                newAccount.dealer = dealer;
                return newAccount;
            });
        }
    },
    watch: {
        selectedGroups() {
            this.checkGroupsAndLoad();
        },
        dateRange() {
            this.loadData();
        },
    },
    async created() {
        // Default to purchase events
        this.eventType = FACEBOOK_OFFLINE_EVENT_TYPES.find(type => type.key === 'purchase');

        this.now = moment();

        this.setTitle();
        this.checkGroupsAndLoad();
    },
    methods: {
        checkGroupsAndLoad() {
            if (this.selectedGroups.length && !this.selectedGroups.includes(null)) {
                this.noGroups = false;
            } else {
                this.noGroups = true;
            }
            this.loadData();
        },
        setTitle() {
            this.$title = `Meta Advertising - ${this.agency.name}`;
        },
        openAdAccount(adAccount) {
            const url = `https://business.facebook.com/adsmanager/manage/campaigns?act=${adAccount}&business_id=${this.agency.facebook_business_id}`;
            window.open(url);
        },
        openAdAccountSettings(adAccount) {
            const url = `https://business.facebook.com/settings/ad-accounts/${adAccount}?business_id=${this.agency.facebook_business_id}`;
            window.open(url);
        },
        goToDealerStats(dealerId) {
            this.$router.push({
                name: 'reports-facebook-advertising',
                params: {
                    dealer_id: dealerId
                }
            });
        },
        async loadData() {
            this.loading = true;
            this.showLoader = true;
            this.loadingGain = 0.3;


            // Ensure calculations are fresh before data is loaded
            this.now = moment();

            this.progressMessage = 'Loading ad accounts...';
            await this.getDealerAdAccounts();

            const count = this.adAccounts.length;
            this.loadingGain = 0.5 - (count * 0.0002);

            this.progressMessage = 'Loading Meta insights...';
            await this.getAdAccountInsights();

            this.progressMessage = 'Loading operation complete!';
            this.loading = false;
        },
        async getDealerAdAccounts() {

            try {
                this.loadingAdAccounts = true;

                const response = await this.$http.get('/dealers', {
                    with_relationships: 'dealers.facebook_ad_account_annotations',
                    with_scopes: {
                        'dealers.agency_equals': {
                            id: this.agency.id
                        },
                        'dealers.groups_in': {
                            ids: this.selectedGroupsIds
                        }
                    },
                    page_size: 1000
                });

                this.dealers = response.data.data;

                const adAccounts = [];

                this.dealers.forEach(dealer => {
                    const adAccount = get(dealer, 'facebook_ad_account_annotations.data[0].facebook_ad_account_id', null);
                    if (adAccount) {
                        adAccounts.push(adAccount);
                    }
                });

                this.adAccounts = adAccounts;
            } catch (error) {
                console.log('Error retreiving dealer ad accounts', error);
            } finally {
                this.loadingAdAccounts = false;
            }
        },
        async getAdAccountInsights() {

            if (!this.adAccounts.length) {
                return;
            }

            try {

                this.loadingInsights = true;

                const fields = [
                    'account_id',
                    'campaign_id',
                    'campaign_name',
                    'account_name',
                    'spend',
                    'reach',
                    'actions'
                ];

                this.insights = await getAgencyInsights({
                    accountIds: this.adAccounts,
                    agencyId: this.agency.id,
                    startDate: moment(this.dateRange.startDate).format('YYYY-MM-DD'),
                    endDate: moment(this.dateRange.endDate).format('YYYY-MM-DD'),
                    campaignFiltering: [{
                        field: 'effective_status',
                        operator: 'IN',
                        value: ['ACTIVE']
                    }],
                    attributionWindows,
                    limit: 150,
                    fields
                });


            } catch (error) {
                console.error('Error retrieving dealer insights', error);
            } finally {
                this.loadingInsights = false;
            }
        },
        exportCSV() {
            const options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                filename: `facebook_offline_events_${new Date().getTime()}`
            };
            const csvExporter = new ExportToCsv(options);
            const result = [];
            const keys = this.headers.map(d => d.id).splice(2);
            this.dealerInsights.map(dealer => {
                const obj = {
                    dealer: dealer.dealer_name,
                    ad_account: dealer.name
                };
                keys.map(k => {
                    obj[k] = dealer.insights[k].formatted;
                });
                result.push(obj);
            });
            csvExporter.generateCsv(result);
        }
    }
};
</script>

<style lang="scss" scoped>
.groups-picker {
    border-bottom: 1px solid $gray-light;
    padding: 15px 40px;
    &__holder {
        max-width: 255px;
    }
}
.action-icon {
    display: inline-block;
    vertical-align: middle;
    margin-top: -3px;
}
</style>
<style>
.offline-events-table .column-total{
    font-weight: 700;
}
.offline-events-table .table-header {
    margin: 1.5rem 3rem;
}
</style>
